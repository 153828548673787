// Commonly used parameters
$base-font-size:    15px;

// Our desktop-screen styles used all over the site
@media ( min-width: 992px ) and ( max-width: 1199px ) {

  body {
    font-size: $base-font-size;
  }

  .product-container {
    h1.product-heading {
      font-size: 2.1em;
    }


    .product-discount-container {
      padding-top: 45px;

      div {
        font-size: $base-font-size;
      }

      .product-discount-box-part2 {
        min-width: 90px;
        sup {
          font-size: 0.7em;
        }
        span {
          font-size: 1.1em;
        }
      }
      .product-discount-box-part4 {
        min-width: 90px;
        sup {
          font-size: 0.7em;
        }
        span {
          font-size: 1.1em;
        }
      }

    }

    .product-price-container {

      .product-price-value {
        font-size: 2.5em;
        sup {
          font-size: 0.5em;
        }
      }

    }

    .product-counter-container {
      width: 475px;
      height: 177px;
      padding: 10px;
      margin: 2em 0 0 -1em;

      h2 {
        font-size: 1.1em;
      }

      .product-counter-terms-container {
      }

    }

  }

  a.front-button:link,
  a.front-button:visited {
    padding: 1.1em;
    font-size: 0.65em;
    height: 65px;

    b {
      font-size: 1.75em;
    }
    img {
      width: 100px;
      height: 65px;
    }

  }

  .product-tomm-container {
    background-position: 45%;
    .fixed-writeup {
      max-width: 320px;
      padding: 0;
      /* font-size: 1.3em;
      padding: 10px 30px 0 0; */
    }

    .product-image {
      img {
        max-width: 200px;
      }
    }

  }

  .no-tomm-deal {
    background: url(../images/bg-product-tomorrow-repeat-x.png) repeat-x top left;
    background-position: -20px center;
    .fixed-writeup {
      max-width: 450px;
      padding: 20px;
      background-color: #808080;
    }
  }


}

