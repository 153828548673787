// Commonly used parameters
$font-stack:        Verdana;
$base-font-size:    16px;
$font-size-sm:      0.8em;
$font-size-reg:     0.9em;
$color-text:        #5c5c5c;
$color-bg:          #ffffff;
$color-bg-light:    #edeaea;
$color-bg-light-ex: #dddada;
$color-dark:        #ff9900;
$color-dark-ex:     #f4901e;
$border-gray:       1px solid #d1cece;
$transition:        all 0.50s;

// Common styles

html {
  height: 100%;
}

.cf {
  clear: both;
}
.float-right {
  float: right;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
  background-color: $color-dark-ex;
  border-color: $color-dark-ex;
}
.pagination>li>a, .pagination>li>span {
  color: $color-dark-ex;
}

// Main site common styles

body {
  color: $color-text;
  background-color: $color-bg;
  font-family: $font-stack;
  font-size: $base-font-size;
}

h1 {
  font: bold 1.8em/1 $font-stack;
  margin-bottom: 0.6em;
}

h2 {
  font: bold 1.2em $font-stack;
  letter-spacing: 0.03em;
  margin-bottom: 0.6em;
}

h3 {
  font: bold 1em $font-stack;
  letter-spacing: 0.03em;
  margin-bottom: 0.6em;
}

h4 {
  font: bold 0.9em $font-stack;
  letter-spacing: 0.03em;
  margin-bottom: 0.6em;
}

p {
  color: $color-text;
  font: $font-size-reg/1 $font-stack;
  margin-bottom: 1em;
}

.align-right {
  text-align: right;
}

.btn-default {
  padding: 0.4em 2.4em;
  color: #fff;
  background-color: #aaa;
  border-radius: 4px;
  font-size: 0.8em;
  font-weight: bold;
  -webkit-transition: $transition;
  transition: $transition;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active {
  color: #fff;
  background-color: #888;
}

.btn-submit {
  padding: 0.4em 2.4em;
  color: #fff;
  background-color: $color-dark;
  border-radius: 4px;
  font-size: 0.8em;
  font-weight: bold;
  -webkit-transition: $transition;
  transition: $transition;
}
.btn-submit:hover,
.btn-submit:focus,
.btn-submit:active {
  color: #fff;
  background-color: $color-dark-ex;
}

.btn-submitting {
  padding: 0.4em 2.4em;
  font-size: 0.8em;
  font-weight: bold;
  color: #fff;
  background-color: #777 !important;
}
.btn-submitting:hover,
.btn-submitting:focus,
.btn-submitting:active {
  color: #fff;
  background-color: #777 !important;
}

.btn-facebook {
  padding: 3px 15px;
  color: #fff;
  background-color: #3b5998;
}
.btn-facebook:hover,
.btn-facebook:active,
.btn-facebook:focus {
  color: #fff;
  background-color: #24417c;
}

.header-gray-bar {
  min-height: 37px;
  background: url(../images/bg-gray-bar.png) no-repeat center center;
  background-size: cover;
  color: #efeeee;
  font: bold 0.9em sans-serif;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1px;
  padding-top: 10px;

  span {
    color: #f59b1a;
  }

}

  .header-welcome {
    font: 0.9em sans-serif;
    text-transform: none;
    position: absolute;
	right: 20px;
  }

.header-main-bar {
  min-height: 172px;
  background: url(../images/bg-header.png) no-repeat center center;
  background-size: cover;
}

.header-logo {
  img {
    max-width: 100%;
  }
}

.main {
  min-height: 55vh;
}

.footer-social {
  min-height: 60px;
  padding-top: 10px;
  background-color: $color-bg-light;
  font: bold 1em $font-stack;
  text-align: center;
  img {
    width: 40px;
    vertical-align: middle;
  }
}

.footer-gray-bar {
  min-height: 37px;
  padding-top: 10px;
  background: url(../images/bg-gray-bar.png) no-repeat center center;
  background-size: cover;
  color: #fff;
}

.footer-copyright {
  text-align: left;
  font-size: $font-size-sm;
}

.footer-links {
  text-align: right;

  a:link,
  a:visited {
    color: #fff;
    font-size: $font-size-sm;
  }
  a:hover {
    color: #ddd;
    text-decoration: underline;
  }

}

// Main site product styles

.product-brand {
  padding-top: 40px;
  float: right;

  label {
    display: block;
    margin-left: 20px;
    color: #666;
    font: bold $font-size-sm $font-stack;
  }
  img {
    float: right;
    max-width: 100%;
    max-height: 90px;
  }
}

.social-icon:hover {
  -webkit-transition: $transition;
  transition: $transition;
  opacity: 0.8;
}

.product-container {
  border: $border-gray;
  padding: 1em 1em 0.7em 1em;
  margin-bottom: 1em;

  h1.product-heading {
    color: #232323;
    font: bold 2.5em/1 $font-stack;
    letter-spacing: -0.07em;
    text-transform: uppercase;
    margin-bottom: 0.4em;
  }

  p.product-detail {
    color: $color-text;
    font: $font-size-sm/1 $font-stack;
    margin-bottom: 1em;
  }

  .product-discount-container {

    font-size: 0; /* to remove gaps between inline-block divs */
    text-align: right;
    padding-top: 50px;
    white-space: nowrap;

    div {
      color: #fff;
      font-size: $base-font-size;
      font-weight: bold;
      height: 60px;
      float: left;
      padding-top: 14px;
      background-repeat: no-repeat;
      background-position: left bottom;
    }

    .product-discount-box-part1 {
      width: 55px;
      background-image: url(../images/prod-disc-bg-1.png);
    }
    .product-discount-box-part2 {
      min-width: 100px;
      background-image: url(../images/prod-disc-bg-2.png);
      background-repeat: repeat-x;
      sup {
        margin-right: -5px;
        font-size: 0.8em;
      }
      span {
        font-size: 1.4em;
      }
    }
    .product-discount-box-part3 {
      width: 30px;
      background-image: url(../images/prod-disc-bg-3.png);
    }
    .product-discount-box-part4 {
      min-width: 100px;
      padding-top: 25px;
      background-image: url(../images/prod-disc-bg-4.png);
      background-repeat: repeat-x;
      sup {
        margin-right: -5px;
        font-size: 0.8em;
      }
      span {
        font-size: 1.4em;
      }
    }
    .product-discount-box-part5 {
      width: 34px;
      background-image: url(../images/prod-disc-bg-5.png);
    }

  } /* product-discount-container end */

  .product-price-container {
    width: 461px;
    height: 285px;
    margin: auto;
    color: #fff;
    background: url('../images/product-price-bg.png') no-repeat left top;

    .product-price-value {
      width: 35%;
      height: 45%;
      padding-top: 10%;
      float: right;
      font-size: 2.5em;
      font-weight: bold;
      text-align: center;
      overflow: hidden;
      sup {
        margin-right: -15px;
        font-size: 0.6em;
      }
    }
    .product-price-value-1 {
      padding-top: 8% !important;
      font-size: 3.8em !important;
    }
    .product-price-value-2 {
      padding-top: 9% !important;
      font-size: 3.2em !important;
    }
    .product-price-value-3 {
      font-size: 2.75em !important;
    }
    .product-price-value-5 {
      padding-top: 12% !important;
      font-size: 2em !important;
    }
    .product-price-value-6 {
      padding-top: 13% !important;
      font-size: 1.75em !important;
    }

    .product-price-heading {
      font-size: 2.7em;
      font-weight: bold;
      padding: 90px 0 0 30px;
    }

    .product-price-sub-heading {
      font-size: 1.3em;
      letter-spacing: 0.1em;
      padding: 15px 0 0 40px;
    }

  }

  .product-counter-container {
    width: 541px;
    height: 177px;
    padding: 15px;
    margin: 2em 0 0 -1em;
    color: #fff;
    background: url('../images/product-counter-bg.png') no-repeat left top;
    text-align: center;

    h2 {
      font: bold 1.2em $font-stack;
      letter-spacing: 0.03em;
    }

    .product-counter-terms-container {
      margin: auto;
      display: table;
      text-align: center;

      .product-counter-term {
        width: 120px;
        height: 124px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        font-weight: bold;
        background: url('../images/product-counter-circle.png') no-repeat center center;
        div {
          font-size: 2em;
        }
        span {
          color: $color-dark;
        }
      }

      .product-counter-colon {
        width: 24px;
        height: 114px;
        display: table-cell;
        background: url('../images/product-counter-colon.png') no-repeat center center;
      }

    }

  }

  .product-image-container {
    min-height: 430px;
    display: table;

    div {
      padding: 20px;
      display: table-cell;
      text-align: center;
      vertical-align: middle;

      img {
        display: block;
        max-width: 100%;
        max-height: 420px;
        width: auto;
        height: auto;
        margin: auto;
      }
    }
  }

  .product-image-thumbs-container {
    text-align: center;
    padding: 15px 5px;
    img {
      display: inline-block;
      max-width: 120px;
      max-height: 56px;
      width: auto;
      height: auto;
      border: 1px solid #e5e5e5;
      padding: 5px;
      margin: 5px;
      cursor: pointer;
    }
  }

  .product-buy-button-wrapper {
    text-align: center;
  }

  a.btn-buy-now:link,
  a.btn-buy-now:visited {
    display: inline-block;
    padding: 0.8em 3em;
    color: #fff;
    background-color: $color-dark;
    border-radius: 6px;
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
    -webkit-transition: $transition;
    transition: $transition;
  }
  a.btn-buy-now:hover {
    background-color: $color-dark-ex;
  }

}

.product-social-container {
  min-height: 115px;
  background-color: $color-bg-light;
  padding-top: 30px;
  color: #616161;
  font: bold 1.2em/3 $font-stack;
  margin-bottom: 20px;
  text-align: right;
}

a.front-button:link,
a.front-button:visited {
  display: block;
  height: 73px;
  border: $border-gray;
  background-color: $color-bg-light;
  padding: 1.2em;
  color: $color-text;
  font: 0.7em $font-stack;
  clear: both;
  margin-bottom: 2em;
  -webkit-transition: $transition;
  transition: $transition;

  b {
    display: block;
    font: bold 1.85em $font-stack;
    color: #232323;
    margin-bottom: 0.1em;
  }

  img {
    margin: -1.2em 1em 0 -1.2em;
    float: left;
  }
}
a.front-button:hover {
  background-color: $color-bg-light-ex;
  img {
    opacity: 0.8;
  }
}

.product-tomm-container {
  min-height: 242px;
  background: url(../images/bg-product-tomorrow.png) no-repeat center center;
  padding-top: 30px;
  margin-top: 1em;
  margin-bottom: 3em;

  .fixed-writeup {
    color: #fff;
    /* font: bold 1.4em/1.7 $font-stack; */
    padding: 10px 50px;
    b {
      color: maroon;
      font-size: 1.01em;
      display: block;
    }
    p {
      color: #ddd;
    }
  }

  .product-image {
    display: table;
    width: 100%;
    height: 220px;

    div {
      width: 100%;
      padding: 20px;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      img {
        max-width: 100%;
        max-height: 200px;
      }
    }
  }

  .product-title {
    display: table;
    height: 220px;

    div {
      display: table-cell;
      vertical-align: middle;
      font: 1.2em/1.2 $font-stack;
      h2 {
        color: red;
        font: bold 1.2em/1.4 $font-stack;
        margin-bottom: 0.4em;
        text-transform: uppercase;
      }
    }
  }

}

.no-tomm-deal {
  background: url(../images/bg-product-tomorrow-repeat-x.png) repeat-x top left;
  background-position: -20px center;
  .fixed-writeup {
    margin-top: -20px;
    padding: 20px;
    background-color: #808080;
  }
}

@media (max-width:1300px) {
  .no-tomm-deal {
    background-position: -80px center;
  }
}

.product-tomm-container-xs {

  .fixed-writeup {
    min-height: 180px;
    background: url(../images/bg-product-tomorrow.png) no-repeat -580px center;
    background-size: auto 95%;
    color: #fff;
    font: bold 1.2em/2 $font-stack;
    padding: 30px 70px;
    div {
      max-width: 100%;
    }
    b {
      color: maroon;
      font-size: 1.01em;
      display: block;
    }
    p {
      color: #ddd;
    }
  }

  .product-image {
    text-align: center;
    padding: 20px;
    img {
      max-width: 220px;
      max-height: 260px;
    }
  }

  .product-title {
    padding-top: 20px;
    text-align: center;
    font: 1.2em/1.2 $font-stack;
    h2 {
      color: red;
      font: bold 1.2em/1.4 $font-stack;
      margin-bottom: 0.4em;
      text-transform: uppercase;
    }
  }

}

.add-deal-page {

  .add-deal-section {

    background-color: #fff;

    h2 {
      padding-bottom: 0.6em;
      border-bottom: 1px solid #eee;
      margin-bottom: 0.6em;
      text-transform: capitalize;
    }

    .ui-datepicker-current-day {
      background-color: $color-bg-light-ex;
      color: #555;
    }

    .ui-state-active {
      background-color: $color-bg-light-ex;
      color: #555;
      a:link,
      a:visited {
        border: none;
      }
    }

    .ui-state-highlight {
      border: none;
      background: transparent;
      color: #555;
      a:link,
      a:visited {
        border: none;
        background-color: $color-dark;
        color: #fff;
      }
    }

    .multi-dates-calendar {
      .ui-datepicker {
        margin: auto;
      }
      .ui-state-disabled {
        opacity: 1;
        .ui-state-default {
          color: white;
          background-color: #d00;
        }
      }
      .ui-state-grayed {
        .ui-state-default {
          border-color: #eee;
          color: #ccc;
          background-color: #eee;
        }
      }
    }

    .multi-dates-selected {
      min-height: 360px;
      padding: 10px;
      background-color: $color-bg-light;
      .label-default {
        font: $font-size-reg/1.1 $font-stack;
        display: inline-block;
        padding: 5px 8px;
        margin: 5px;
      }
    }

    .deal-total-cost {
      float: left;
      font: bold $font-size-reg/1 $font-stack;
      span {
        color: $color-dark;
      }
    }

    .form-wrapper {
      padding: 15px 0 25px 0;
      max-width: 850px;
      min-width: 260px;
      margin: auto;

      label {
        line-height: 2.4;
        font-size: $font-size-reg;
        font-weight: bold;
      }

      .deal-add-social-icon {
        width: 32px;
      }
    }

    .button-wrapper {
      padding-top: 15px;
      text-align: right;
    }

  }

}

.multi-dates-calendar-show {
  width: 550px;
  margin: auto;
  .ui-state-highlight {
    border: none;
    background-color: inherit;
  }
  .ui-state-disabled {
    opacity: 1;
    .ui-state-default {
      color: white;
      background-color: #d00;
    }
  }
  a:hover {
    background-color: #f6f6f6;
    cursor: default;
  }
  a:link,
  a:visited {
    color: #444;
    background-color: #f6f6f6;
    border: 1px solid #c5c5c5;
  }
}

.advertiser-selected-date {

}

/* Section used in modals to divide them */
.modal-section {
  h4 {
    line-height: 2;
    padding-bottom: 10px;
    margin-top: 0px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
}

.navbar-nav.nav-justified > li{
  float:none;
}
.nav-mem{background-repeat:repeat-x;filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFF", endColorstr="#cdd6db");background-image:-khtml-gradient(linear, left top, left bottom, from(#FFFFFF), to(#cdd6db));background-image:-moz-linear-gradient(top, #FFFFFF, #cdd6db);background-image:-ms-linear-gradient(top, #FFFFFF, #cdd6db);background-image:-webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFFFF), color-stop(100%, #cdd6db));background-image:-webkit-linear-gradient(top, #FFFFFF, #cdd6db);background-image:-o-linear-gradient(top, #FFFFFF, #cdd6db);background-image:linear-gradient(#FFFFFF, #cdd6db);-webkit-font-smoothing:antialiased;border-color:#cccccc;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;margin:10px 0px}
.nav-mem .navbar-nav {margin:0px}
.nav-mem .navbar-nav > .active > a,
.nav-mem .navbar-nav > .active > a:hover,
.nav-mem .navbar-nav > .active > a:focus {margin-left:0;color: #555555;background-repeat:repeat-x;filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#cdd6db", endColorstr="#FFFFFF");background-image:-khtml-gradient(linear, left top, left bottom, from(#cdd6db), to(#FFFFFF));background-image:-moz-linear-gradient(top, #cdd6db, #FFFFFF);background-image:-ms-linear-gradient(top, #cdd6db, #FFFFFF);background-image:-webkit-gradient(linear, left top, left bottom, color-stop(0%, #cdd6db), color-stop(100%, #FFFFFF));background-image:-webkit-linear-gradient(top, #cdd6db, #FFFFFF);background-image:-o-linear-gradient(top, #cdd6db, #FFFFFF);background-image:linear-gradient(#cdd6db, #FFFFFF);-webkit-font-smoothing:antialiased}
.nav-mem ul>li>a:link,.nav-mem ul>li>a:visited{font:bold 11px/15px Verdana;color:#727272}
.nav-mem ul>li>a>i{font-size:24px;display:block;margin:auto;margin-bottom:3px}
.nav-mem .navbar-toggle{border-color:#bbb;background-color:#ddd}
.nav-mem .navbar-toggle .icon-bar{background-color:#bbb}
@media (max-width:767px){
  .nav-mem ul>li>a:link,.nav-mem ul>li>a:visited{min-height:30px;text-align:left}
  .nav-mem ul>li>a>i{display:none}
}

.advertiser-well {
  text-align: center;
  min-height: 240px;
  h1 {
    font-size: 1.4em;
  }
  .control-label,
  .form-control-static {
    text-align: left;
    font-weight: bold;
  }
}

.table-listing {

  td {
    text-align: left;
    word-break: break-all;
  }

}

.team-member {
  cursor: pointer;
  position: relative;
  .team-member-main {
    padding: 2em 1em;
  }
  img {
    display: block;
    margin: auto;
    border-radius: 50%;
    max-width: 100%;
    height: auto;
    margin-bottom: 1em;
  }
  .team-member-hover {
    position: absolute;
    width: 100%;
    color: #fff;
    background-color: $color-dark-ex;
    display: none;
    z-index: 99;
    p {
      padding: 0.7em 0;
      color: #fff;
    }
    .social-icon img {
      display: inline;
      width: 32px;
    }
  }
}
.team-member:hover {
  color: #fff;
  background-color: $color-dark-ex;
  .team-member-hover {
    display: block;
  }
}

.newsletter-form-container {
  padding: 0;
  margin-top: -5px !important;
  text-align: center;

  .newsletter-form {
    display: inline-block;
    /* padding: 15px;
    border: 1px solid #d1cece;
    background-color: #edeaea; */
    font: 1em Verdana;
    text-align: center;

    .tnp-field {
      padding: 5px;
      input {
        color: #333;
      }
    }

    .tnp-button {
      display: inline-block;
      padding: 0.4em 2em;
      color: #fff;
      background-color: #ff9900;
      border: none;
      border-radius: 6px;
      font-size: 1em;
      -webkit-transition: $transition;
      transition: $transition;
    }

  }

}
