// Commonly used parameters
$base-font-size:    12px;

// Our small-mobile-screen styles used all over the site
@media ( max-width: 413px ) {

  body {
    min-width: 360px;
    font-size: $base-font-size;
  }

  .product-brand {
    padding-top: 0px;
    min-height: 60px;
    img {
      margin-top: -15px;
      max-height: 45px;
    }
    label {
      float: left;
      margin-right: 10px;
    }
  }

  .product-container {

    h1.product-heading {
      font-size: 1.8em;
    }

    p.product-detail {
      font-size: 0.9em;
    }

    .product-discount-container {
      width: 320px;
      margin: auto;
      padding-top: 0px;
      div {
        font-size: 12px;
        height: 54px;
        background-size: 100%;
      }
      .product-discount-box-part1 {
        width: 49.5px;
      }
      .product-discount-box-part2 {
        min-width: 80px;
        background-size: auto 54px;
        sup {
          font-size: 0.6em;
        }
        span {
          font-size: 1em;
        }
      }
      .product-discount-box-part3 {
        width: 27px;
      }
      .product-discount-box-part4 {
        min-width: 80px;
        background-size: auto 41.8px;
        sup {
          font-size: 0.6em;
        }
        span {
          font-size: 1em;
        }
      }
      .product-discount-box-part5 {
        width: 31px;
      }
    }

    .product-price-container {
      max-width: 100%;
      background-size: 100%;
      position: relative;
      z-index: 1;

      .product-price-value {
        font-size: 2.6em;
        sup {
          font-size: 0.5em;
        }
      }

      .product-price-heading {
        font-size: 2em;
        padding: 70px 0 0 30px;
      }

      .product-price-sub-heading {
        font-size: 1.1em;
        padding: 15px 0 0 40px;
      }

    }

    .product-counter-container {
      width: 310px;
      height: 140px;
      padding: 7px;
      margin: 15px auto 0 auto;

      h2 {
        font-size: 1.1em;
      }

      .product-counter-terms-container {
        .product-counter-term {
          width: 100px;
          height: 100px;
          background-size: 100%;
          div {
          }
        }
        .product-counter-colon {
          width: 20px;
          height: 100px;
          background-position: 1px 25px;
        }
      }

    }

    .product-image-container {
      width: 100%;
      min-height: 230px;
      margin: -85px auto 0 auto;
      img {
        display: block;
        max-width: 100%;
	    max-height: 350px;
        height: auto;
        margin: auto;
      }
      z-index: 0;
    }

    .product-buy-button-wrapper {
      /* margin-top: -50px; */
      text-align: center;
    }
    a.btn-buy-now:link,
    a.btn-buy-now:visited {
      padding: 0.6em 2.3em;
      font-size: 1em;
    }

  }

  .social-icon img {
    width: 10vw;
  }

  a.front-button:link,
  a.front-button:visited {
    padding: 1.1em;
    font-size: 0.8em;
    height: 65px;
    img {
      width: 100px;
      height: 65px;
    }

  }

  .product-tomm-container {
    background-position: 45%;
    .fixed-writeup {
      font-size: 1.3em;
      padding: 10px 30px 0 0;
    }

    .product-image {
      img {
        max-width: 200px;
      }
    }

  }

  .product-tomm-container-xs {

    .fixed-writeup {
      padding: 30px 50px;
    }

  }

  .deal-add-social-icon {
    margin-top: 10px;
  }

  .multi-dates-calendar {
    margin:15px auto;
    .ui-datepicker {
      width: 100%;
      max-width: 300px;
      table {
        line-height: 2;
      }
    }
  }

  .team-member {
    .team-member-hover {
      position: relative;
      color: #5c5c5c;
      background-color: #fff;
      display: block;
      p {
        color: #5c5c5c;
      }
    }
  }
  .team-member:hover {
    color: #5c5c5c;
    background-color: #fff;
  }

}
