// Commonly used parameters
$base-font-size:    14px;

// Our tablet-screen styles used all over the site
@media ( min-width: 768px ) and ( max-width: 991px ) {

  body {
    font-size: $base-font-size;
  }

  .product-container {

    h1.product-heading {
      font-size: 1.8em;
    }

    p.product-detail {
      font-size: 0.85em;
    }

    .product-discount-container {
      padding-top: 0px;
      margin-top: -20px;
    }

    .product-price-container {
      position: relative;
      z-index: 1;
      .product-price-value {
        font-size: 2.5em;
        sup {
          font-size: 0.5em;
        }
      }

    }

    .product-counter-container {
      width: 400px;
      height: 160px;
      padding: 7px;
      margin: 15px auto 0 auto;

      h2 {
        font-size: 1.1em;
      }

      .product-counter-terms-container {
        .product-counter-term {
          width: 100px;
          height: 100px;
          background-size: 100%;
          div {
          }
        }
      }

    }

    .product-image-container {
      width: 500px;
      margin: -85px auto 0 auto;
      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: auto;
      }
      z-index: 0;
    }

    .product-buy-button-wrapper {
      text-align: left;
    }
    a.btn-buy-now:link,
    a.btn-buy-now:visited {
      padding: 0.6em 2.3em;
      font-size: 1em;
    }

  }

  a.front-button:link,
  a.front-button:visited {
    padding: 1.1em;
    font-size: 0.65em;
    height: 65px;

    b {
      font-size: 1.75em;
    }
    img {
      width: 100px;
      height: 65px;
    }

  }

  .product-tomm-container {
    background-position: -410px center;
    .fixed-writeup {
      font-size: 1.3em;
      padding: 10px 30px 0 0;
    }
    .product-image {
      img {
        max-width: 200px;
      }
    }
  }
  @media (max-width:900px) {
    .product-tomm-container {
      background-position-x: -430px;
    }
  }
  @media (max-width:860px) {
    .product-tomm-container {
      background-position-x: -460px;
    }
  }
  @media (max-width:780px) {
    .product-tomm-container {
      background-position-x: -490px;
    }
  }

  .team-member {
    .team-member-hover {
      position: relative;
      color: #5c5c5c;
      background-color: #fff;
      display: block;
      p {
        color: #5c5c5c;
      }
    }
  }
  .team-member:hover {
    color: #5c5c5c;
    background-color: #fff;
  }

}

@media (max-width:990px) {
  .no-tomm-deal {
    font-size: 90%;
    .fixed-writeup {
      padding: 20px;
    }
  }
}

